<template>
  <div class="btn-group" role="group" style="display: flex;">

    <template v-if="addPrintScreen">
      <span @click=printScreen >
        <span class="toolbar-button" v-show="$store.state.ctrlPressed">
          <Icon name=camera />
        </span>
      </span>
      &thinsp;
    </template>
    
    <template v-for="t in fullToolbar">
        <div class="input-group" v-if="t.input">
          <input type="search" class="form-control quick-search" style="width: auto; last-child:not" :placeholder=t.placeholder 
           :name=t.name @input=debouncedInput(t.name,$event.target.value) />
        </div>
        <span v-else-if="t.inline" @click=click(t)>
          <span class="toolbar-button" :name=t.name>
            <Icon v-if=t.icon :name=t.icon class="cursor-pointer" :title=t.tooltip :class="{'fa-spin': t.name&&t.name==spinning}" />
            <span v-if="t.text" class="text">{{t.text}}</span>
          </span>
        </span>

        <div class="btn-group" role="group" v-else-if="t.submenu">
          <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <Icon v-if=t.icon :name=t.icon class="cursor-pointer" :title=t.tooltip :class="{'fa-spin': t.name&&t.name==spinning}" />
            <span v-if="t.text" class="text">{{t.text}}</span>
            <span class="caret"></span>
          </button>

          <ul class="dropdown-menu">
            <li v-for="rec in t.submenu" :key=rec.name >
              <a class="cursor-pointer" @click.prevent=click(rec) >{{rec.text}}</a>
            </li>
          </ul>
        </div>
        
        <button type=button :name=t.name v-else-if="t.toggle"
         :class="{'btn-sm': low, 'btn': !low}"
         class="btn-default toolbar-button" @click=toggle(t)
        >
            <Icon :name="t.opened ? 'chevron-up' : 'chevron-down'" class="cursor-pointer" />
            &thinsp;<span class="text">{{t.opened ? t.toggle[1] : t.toggle[0]}}</span>
        </button>

        <template v-else>
          <button type="button" :name=t.name
           :class="{'btn-sm': low, 'btn': !low}"
           class="btn-default toolbar-button" @click=click(t)>
            <Icon :name=t.icon v-if=t.icon class="cursor-pointer" :class="{'fa-spin': t.name&&t.name==spinning}" />
            <template v-if="t.text">&thinsp;<span class="text">{{t.text}}</span></template>
          </button>
	</template>
	&thinsp;
    </template>
  </div>
</template>

<script>

import _ from "underscore"
import radio from "radio"

export default {

  name: "Toolbar",
  
  props: {
    name: String, 
    buttons: Array,
    addPrintScreen: {
      type: Boolean,
      default: false,
    },
    spinning: String,
    low: {
      type: Boolean,
      default: false
    }
  },
  
  data()
  {
    return {
      fullToolbar: this.buttons || [],
    }
  },
  
  created()
  { 
    const me = this
    if (!me.name) return
    
    radio.channel(`toolbar:${me.name}:button`).reply("get-state", name => 
    {
      const b = me.buttons.find(el => el.name==name)
      if (b) return b.opened
      return null
    })
  },

  mounted()
  {
    this.$on("setToolbarButton", rec => this.fullToolbar.push(rec))
    this.$on("clear-input", ({name}) => 
    {
      const input = this.$el.querySelector(`input[name=${name}]`)
      if (input) input.value = ""
    })
  },
  
  methods: {
    click(t)
    {
      if (t.name) this.$emit("toolbar-click", {from: t.name})      
    },
    
    input(name, value)
    {
      if (name) this.$emit("toolbar-input", {from: name, value: value})
    },

    printScreen()
    {
      this.$emit("print-screen")
    },
    
    toggle(t)
    {
      t.opened = !t.opened
      if (t.name) this.$emit("toolbar-click", {from: t.name, opened: t.opened})
    },
  },
  
  computed: {
    debouncedInput()
    {
      return _.debounce(this.input, 500)
    }                          
  },

}
</script>

<style scoped>
  button.btn-sm 
  {
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    border: solid 1px rgb(204,204,204);
  }

  input.form-control 
  {
    margin-left: 0.5em;
  }

  input.quick-search {
    border-radius: 4px !important;
  }

</style>
