var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.border
    ? _c("div", [
        _c(
          "div",
          { staticClass: "panel panel-info" },
          [
            _vm.heading
              ? [
                  _c("div", { staticClass: "panel-heading" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "space-between"
                        }
                      },
                      [
                        _c("div", [
                          _vm.icon
                            ? _c(
                                "span",
                                {
                                  staticClass: "glyphicon",
                                  class: "glyphicon-" + _vm.icon,
                                  attrs: { "aria-hidden": "true" }
                                },
                                [_vm._v(" ")]
                              )
                            : _vm._e(),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.title) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c("Toolbar", {
                          ref: "toolbar",
                          attrs: {
                            buttons: _vm.toolbarData.buttons,
                            addPrintScreen: true,
                            spinning: _vm.toolbarData.spinning,
                            low: _vm.toolbarData.low,
                            name: _vm.toolbarData.name
                          },
                          on: {
                            "toolbar-click": _vm.toolbarClick,
                            "print-screen": _vm.printScreen
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { class: { "panel-body": _vm.padding } },
              [_vm._t("default")],
              2
            )
          ],
          2
        )
      ])
    : _c(
        "div",
        { class: { "panel-body": _vm.padding } },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }